import { urls } from "@/urls";

export const embedRoutes = {
  path: urls.embed.entry,
  async lazy() {
    const { Root } = await import("./Root");
    return { Component: Root };
  },
  children: [
    {
      path: urls.embed.root,
      async lazy() {
        const { Root } = await import("../embedBot/Root");
        return { Component: Root };
      },
      children: [
        {
          path: urls.embed.home,
          async lazy() {
            const { Home } = await import("../embedBot/Home");
            return { Component: Home };
          },
        },
        {
          path: urls.embed.chat,
          async lazy() {
            const { Chat } = await import("../embedBot/Chat");
            return { Component: Chat };
          },
        },
        {
          path: urls.embed.thank_you,
          async lazy() {
            const { ThankYou } = await import("../embedBot/ThankYou");
            return { Component: ThankYou };
          },
        },
        {
          path: urls.embed.reference_image,
          async lazy() {
            const { ReferenceImage } = await import(
              "../embedBot/ReferenceImage"
            );
            return { Component: ReferenceImage };
          },
        },
        {
          path: urls.embed.design_brief,
          async lazy() {
            const { DesignBrief } = await import("../embedBot/DesignBrief");
            return { Component: DesignBrief };
          },
        },
      ],
    },
    {
      path: urls.embed_mood_board.root,
      async lazy() {
        const { Root } = await import("../embedMoodBoard/Root");
        return { Component: Root };
      },
      children: [
        {
          path: urls.embed_mood_board.home,
          async lazy() {
            const { Home } = await import("../embedMoodBoard/Home");
            return { Component: Home };
          },
        },
        {
          path: urls.embed_mood_board.chat,
          async lazy() {
            const { Chat } = await import("../embedMoodBoard/Chat");
            return { Component: Chat };
          },
        },
        {
          path: urls.embed_mood_board.reference_image,
          async lazy() {
            const { ReferenceImage } = await import(
              "../embedMoodBoard/ReferenceImage"
            );
            return { Component: ReferenceImage };
          },
        },
        {
          path: urls.embed_mood_board.design_brief,
          async lazy() {
            const { DesignBrief } = await import(
              "../embedMoodBoard/DesignBrief"
            );
            return { Component: DesignBrief };
          },
        },
        {
          path: urls.embed_mood_board.thank_you,
          async lazy() {
            const { ThankYou } = await import("../embedMoodBoard/ThankYou");
            return { Component: ThankYou };
          },
        },
      ],
    },
  ],
};
