import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { adminRoutes } from "./pages/admin/Route";
import { botRoutes } from "./pages/bot/Route";
import {
  forgetPasswordRoutes,
  interiorPlanRoutes,
  loginRoutes,
  planRoutes,
  registrationRoutes,
} from "./pages/login/Route";
import { demoBotRoutes } from "./pages/demoBot/Route";
import AuthProvider from "./provider/AuthProvider";
import { urls } from "./urls";
import { ErrorPage } from "./pages/error/ErrorPage";
import { sharedAdminRoutes } from "./pages/admin/SharedRoute";
import { moodBoardRoutes, moodBoardV3Routes } from "./pages/moodBoard/Route";
import TagManager from "react-gtm-module";
import { demoMoodBoardRoutes } from "./pages/demoMoodBoard/Route";
import { outerInteriorPlanRoutes, outerPlanRoutes } from "./pages/plan/Route";
import { embedRoutes } from "./pages/embed/Route";

const tagManagerArgs = {
  gtmId: "GTM-TTZMPHTS",
};

TagManager.initialize(tagManagerArgs);

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Navigate to={urls.login} replace />,
      },
      loginRoutes,
      registrationRoutes,
      planRoutes,
      interiorPlanRoutes,
      botRoutes,
      adminRoutes,
      moodBoardRoutes,
      moodBoardV3Routes,
      forgetPasswordRoutes,
    ],
  },
  embedRoutes,
  sharedAdminRoutes,
  demoBotRoutes,
  demoMoodBoardRoutes,
  outerPlanRoutes,
  outerInteriorPlanRoutes,
]);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
