import { urls } from "@/urls";

export const adminRoutes = {
  async lazy() {
    const { Root } = await import("./Root");
    return { Component: Root };
  },
  children: [
    {
      path: urls.admin.dashboard,
      async lazy() {
        const { Dashboard } = await import("./layout/Dashboard");
        return { Component: Dashboard };
      },
    },
    {
      path: urls.admin.client,
      async lazy() {
        const { ClientRequest } = await import("./layout/ClientRequest");
        return { Component: ClientRequest };
      },
    },
    {
      path: urls.admin.account,
      async lazy() {
        const { Account } = await import("./layout/Account");
        return { Component: Account };
      },
    },
    {
      path: urls.admin.account_info_edit,
      async lazy() {
        const { AccountInfoEdit } = await import("./layout/AccountInfoEdit");
        return { Component: AccountInfoEdit };
      },
    },
    {
      path: urls.admin.account_password_edit,
      async lazy() {
        const { AccountPasswordEdit } = await import(
          "./layout/AccountPasswordEdit"
        );
        return { Component: AccountPasswordEdit };
      },
    },
    {
      path: urls.admin.account_chatbot_color_edit,
      async lazy() {
        const { AccountChatbotColorEdit } = await import(
          "./layout/AccountChatbotColorEdit/AccountChatbotColorEdit"
        );
        return { Component: AccountChatbotColorEdit };
      },
    },
    {
      path: urls.admin.demo,
      async lazy() {
        const { ClientRequestDemo } = await import(
          "./layout/ClientRequestDemo"
        );
        return { Component: ClientRequestDemo };
      },
    },
  ],
};
